<template>
    <div>
        <div>
            <AdminTitleBar
                :title="loc('Stats Summary Reports')"
                v-on:actionhandler="handleAction"
            />
        </div>
        <v-row class="mt-n6">
            <v-col><h2>{{  loc(stateView) }} </h2></v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right" style="max-width: 400px;">
                <v-select :items="titleBarActions" v-model="stateView"/>
            </v-col>
        </v-row>
        <div v-if="!statsDigest || isLoading">No Data Yet</div>
        <div v-else-if="stateView === 'Counts'">
            <v-row>
                <v-col></v-col>
                <v-col cols="3" class="label">30 Days</v-col>
                <v-col cols="3" class="label">90 Days</v-col>
                <v-col cols="3" class="label">Total</v-col>
            </v-row>
            <v-row>
                <v-col cols="3" class="label">Lesson Views</v-col>
                <v-col cols="3">{{statsDigest.counts.thirty.lessons}}</v-col>
                <v-col cols="3">{{statsDigest.counts.ninety.lessons}}</v-col>
                <v-col cols="3">{{statsDigest.counts.total.lessons}}</v-col>
            </v-row>
            <v-row>
                <v-col cols="3" class="label">Page Views</v-col>
                <v-col cols="3">{{statsDigest.counts.thirty.pages}}</v-col>
                <v-col cols="3">{{statsDigest.counts.ninety.pages}}</v-col>
                <v-col cols="3">{{statsDigest.counts.total.pages}}</v-col>
            </v-row>
            <v-row>
                <v-col cols="3" class="label">Users</v-col>
                <v-col cols="3">{{statsDigest.counts.thirty.uniqueusers}}</v-col>
                <v-col cols="3">{{statsDigest.counts.ninety.uniqueusers}}</v-col>
                <v-col cols="3">{{statsDigest.counts.total.uniqueusers}}</v-col>
            </v-row>
            <v-row>
                <v-col cols="3" class="label">Sessions</v-col>
                <v-col>{{statsDigest.counts.thirty.sessions}}</v-col>
                <v-col>{{statsDigest.counts.ninety.sessions}}</v-col>
                <v-col>{{statsDigest.counts.total.sessions}}</v-col>
            </v-row>
        </div>
        <div v-else-if="stateView === 'Courses'">

            <v-row > 
                <v-col cols="4">
                    <h3>30 Days</h3>
                    <ul style="list-style: none; padding-left:0" >
                        <li v-for="(item) in statsDigest.courses.thirty" :key="'courses:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>90 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.courses.ninety" :key="'courses:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>Total</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.courses.ninety" :key="'courses:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
            </v-row>
        </div>        
        <div v-else-if="stateView === 'Referers'">
            <v-row > 
                <v-col cols="4">
                    <h3>30 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.referer.thirty" :key="'referer:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>90 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.referer.ninety" :key="'referer:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>Total</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.referer.ninety" :key="'referer:' + item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
            </v-row>
        </div>      
        <div v-else-if="stateView === 'Pages'">
            <v-row > 
                <v-col cols="4">
                    <h3>30 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.pages.thirty" :key="'pages:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>90 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.pages.ninety" :key="'pages:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>Total</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.pages.ninety" :key="'pages:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
            </v-row>
        </div>      
        <div v-else-if="stateView === 'Lessons'">
            <v-row > 
                <v-col cols="4">
                    <h3>30 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.lessons.thirty" :key="'lessons:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>90 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.lessons.ninety" :key="'lessons:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <h3>Total</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.lessons.ninety" :key="'lessons:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
            </v-row>
        </div>      
        <div v-else-if="stateView === 'Materials'">
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="text-right" style="max-width: 400px;">
                    <v-select 
                        
                        :label="loc('Filter By Course')"
                        :items="courselist"
                        v-model="coursefilter"
                    />
                </v-col>
            </v-row>
            <v-row > 
                <v-col cols="12">
                    <h3>30 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.materials.thirty" :key="'materials:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="12">
                    <h3>90 Days</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.materials.ninety" :key="'materials:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
                <v-col cols="12">
                    <h3>Total</h3>
                    <ul style="list-style: none; padding-left:0">
                        <li v-for="(item) in statsDigest.materials.ninety" :key="'materials:'+item.name"> {{ item.name }}: {{item.value}}</li>
                    </ul>
                </v-col>
            </v-row>
        </div>             
        <v-row>
            <v-col class="text-right text-caption mt-10" cols="12"><span class='mr-5'>30 Days: {{ showDates.thirty }},</span> <span class='mr-5'>90 Days: {{ showDates.ninety }},</span> <span class='mr-5'>Data begins: {{ statsDigest.firstday }}</span></v-col>
        </v-row>

        <pre v-if="!isLoading && debug">
            {{ stateView }} 
            {{ statsDigest }}
        </pre>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
import { formatDate } from "../lib/filter.js";
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';


const today = formatDate()
const thirtyDays = formatDate(Date.now() - (1000 * 60 * 60 * 24 * 30))
const ninetyDays = formatDate(Date.now() - (1000 * 60 * 60 * 24 * 90))

export default {
    name: "StatsReport",
    components: { AdminTitleBar },
    data() {
        return {
            // courselist: [],
            coursefilter: 'All',
            debug: false,
            statsData: false,
            isLoading: true,
            stateView: 'Counts',
            stateScope: 'summary',
            titleBarActions: [
                { text: "Counts", key: "Counts" },
                { text: "Courses", key: "Courses" },
                { text: "Lessons", key: "Lessons" },
                { text: "Materials", key: "Materials" },
                { text: "Referers", key: "Referers" },
                { text: "Pages", key: "Pages" },
            ]
        }
    },
    computed: {
        ...mapState(['siteconfig', 'users', 'courses', 'content', 'reports', 'localize']),
        statsDigest: function() {
            if(!this.statsData) return {};
            let coursefilter = (this.coursefilter === 'All') ? false : this.coursefilter;
            console.log("Computing statsDigest", today, thirtyDays, ninetyDays)
            let out = {
                firstday: false,
                counts: {
                    thirty: { },
                    ninety: {},
                    total: {}
                },
                courses: {
                    thirty: {},
                    ninety: {},
                    total: {}
                },
                lessons: {
                    thirty: {},
                    ninety: {},
                    total: {}
                },
                materials: {
                    thirty: {},
                    ninety: {},
                    total: {}
                },
                referer: {
                    thirty: {},
                    ninety: {},
                    total: {}
                },
                pages: {
                    thirty: {},
                    ninety: {},
                    total: {}
                },
            };
            for(let d in this.statsData.days){
                if(d > today) continue; // this shouldn't happen?
                if(!out.firstday || d < out.firstday) out.firstday = d;
                if(d >= thirtyDays && d <= today ){
                    out.counts.thirty.lessons = out.counts.thirty.lessons ? out.counts.thirty.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.thirty.pages = out.counts.thirty.pages ? out.counts.thirty.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.thirty.sessions = out.counts.thirty.sessions ? out.counts.thirty.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.thirty.uniqueusers = out.counts.thirty.uniqueusers ? out.counts.thirty.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;
                    
                    out.counts.ninety.lessons = out.counts.ninety.lessons ? out.counts.ninety.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.ninety.pages = out.counts.ninety.pages ? out.counts.ninety.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.ninety.sessions = out.counts.ninety.sessions ? out.counts.ninety.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.ninety.uniqueusers = out.counts.ninety.uniqueusers ? out.counts.ninety.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;

                    out.counts.total.lessons = out.counts.total.lessons ? out.counts.total.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.total.pages = out.counts.total.pages ? out.counts.total.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.total.sessions = out.counts.total.sessions ? out.counts.total.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.total.uniqueusers = out.counts.total.uniqueusers ? out.counts.total.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;

                    for(let k in this.statsData.days[d].courses){
                        out.courses.thirty[k] = (out.courses.thirty[k] !== undefined) ? out.courses.thirty[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                        out.courses.ninety[k] = (out.courses.ninety[k] !== undefined) ? out.courses.ninety[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                        out.courses.total[k] = (out.courses.total[k] !== undefined) ? out.courses.total[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                    }
                    for(let k in this.statsData.days[d].referer){
                        out.referer.thirty[k] = (out.referer.thirty[k] !== undefined) ? out.referer.thirty[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                        out.referer.ninety[k] = (out.referer.ninety[k] !== undefined) ? out.referer.ninety[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                        out.referer.total[k] = (out.referer.total[k] !== undefined) ? out.referer.total[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                    }
                    for(let k in this.statsData.days[d].pagelist){
                        out.pages.thirty[k] = (out.pages.thirty[k] !== undefined) ? out.pages.thirty[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                        out.pages.ninety[k] = (out.pages.ninety[k] !== undefined) ? out.pages.ninety[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                        out.pages.total[k] = (out.pages.total[k] !== undefined) ? out.pages.total[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                    }       
                    for(let k in this.statsData.days[d].lessonlist){
                        out.lessons.thirty[k] = (out.lessons.thirty[k] !== undefined) ? out.lessons.thirty[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                        out.lessons.ninety[k] = (out.lessons.ninety[k] !== undefined) ? out.lessons.ninety[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                        out.lessons.total[k] = (out.lessons.total[k] !== undefined) ? out.lessons.total[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                    }     
                    for(let k in this.statsData.days[d].materials){
                        if(this.coursefilter && (this.coursefilter !== 'All' && k.indexOf(this.coursefilter) !== 0)) continue;
                        out.materials.thirty[k] = (out.materials.thirty[k] !== undefined) ? out.materials.thirty[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                        out.materials.ninety[k] = (out.materials.ninety[k] !== undefined) ? out.materials.ninety[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                        out.materials.total[k] = (out.materials.total[k] !== undefined) ? out.materials.total[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                    }                                 
                } else if(d >= ninetyDays ){
                    out.counts.ninety.lessons = out.counts.ninety.lessons ? out.counts.ninety.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.ninety.pages = out.counts.ninety.pages ? out.counts.ninety.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.ninety.sessions = out.counts.ninety.sessions ? out.counts.ninety.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.ninety.uniqueusers = out.counts.ninety.uniqueusers ? out.counts.ninety.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;

                    out.counts.total.lessons = out.counts.total.lessons ? out.counts.total.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.total.pages = out.counts.total.pages ? out.counts.total.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.total.sessions = out.counts.total.sessions ? out.counts.total.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.total.uniqueusers = out.counts.total.uniqueusers ? out.counts.total.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;

                    for(let k in this.statsData.days[d].courses){
                        out.courses.ninety[k] = (out.courses.ninety[k] !== undefined) ? out.courses.ninety[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                        out.courses.total[k] = (out.courses.total[k] !== undefined) ? out.courses.total[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                    }
                    for(let k in this.statsData.days[d].referer){
                        out.referer.ninety[k] = (out.referer.ninety[k] !== undefined) ? out.referer.ninety[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                        out.referer.total[k] = (out.referer.total[k] !== undefined) ? out.referer.total[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                    }
                    for(let k in this.statsData.days[d].pagelist){
                        out.pages.ninety[k] = (out.pages.ninety[k] !== undefined) ? out.pages.ninety[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                        out.pages.total[k] = (out.pages.total[k] !== undefined) ? out.pages.total[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                    }  
                    for(let k in this.statsData.days[d].lessonlist){
                        out.lessons.ninety[k] = (out.lessons.ninety[k] !== undefined) ? out.lessons.ninety[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                        out.lessons.total[k] = (out.lessons.total[k] !== undefined) ? out.lessons.total[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                    }  
                    for(let k in this.statsData.days[d].materials){
                        if(this.coursefilter && (this.coursefilter !== 'All' && k.indexOf(this.coursefilter) !== 0)) continue;
                        out.materials.ninety[k] = (out.materials.ninety[k] !== undefined) ? out.materials.ninety[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                        out.materials.total[k] = (out.materials.total[k] !== undefined) ? out.materials.total[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                    }                                          
                } else {
                    out.counts.total.lessons = out.counts.total.lessons ? out.counts.total.lessons + this.statsData.days[d].lessons : this.statsData.days[d].lessons;
                    out.counts.total.pages = out.counts.total.pages ? out.counts.total.pages + this.statsData.days[d].pages : this.statsData.days[d].pages;
                    out.counts.total.sessions = out.counts.total.sessions ? out.counts.total.sessions + this.statsData.days[d].sessions : this.statsData.days[d].sessions;
                    out.counts.total.uniqueusers = out.counts.total.uniqueusers ? out.counts.total.uniqueusers + this.statsData.days[d].uniqueusers : this.statsData.days[d].uniqueusers;

                    for(let k in this.statsData.days[d].courses){
                        out.courses.total[k] = (out.courses.total[k] !== undefined) ? out.courses.total[k] + this.statsData.days[d].courses[k] : this.statsData.days[d].courses[k];
                    }
                    for(let k in this.statsData.days[d].referer){
                        out.referer.total[k] = (out.referer.total[k] !== undefined) ? out.referer.total[k] + this.statsData.days[d].referer[k] : this.statsData.days[d].referer[k];
                    }
                    for(let k in this.statsData.days[d].pagelist){
                        out.pages.total[k] = (out.pages.total[k] !== undefined) ? out.pages.total[k] + this.statsData.days[d].pagelist[k] : this.statsData.days[d].pagelist[k];
                    }  
                    for(let k in this.statsData.days[d].lessonlist){
                        out.lessons.total[k] = (out.lessons.total[k] !== undefined) ? out.lessons.total[k] + this.statsData.days[d].lessonlist[k] : this.statsData.days[d].lessonlist[k];
                    }   
                    for(let k in this.statsData.days[d].materials){
                        if(this.coursefilter && (this.coursefilter !== 'All' && k.indexOf(this.coursefilter) !== 0)) continue;
                        out.materials.total[k] = (out.materials.total[k] !== undefined) ? out.materials.total[k] + this.statsData.days[d].materials[k] : this.statsData.days[d].materials[k];
                    }                     
                }

            }
            let r30 = [], r90 = [], rtot = [];
            let p30 = [], p90 = [], ptot = [];
            let c30 = [], c90 = [], ctot = [];
            let l30 = [], l90 = [], ltot = [];
            let m30 = [], m90 = [], mtot = [];

            for(let k in out.courses.thirty){ c30.push({name: k, value:out.courses.thirty[k]}) }
            for(let k in out.courses.ninety){ c90.push({name: k, value:out.courses.ninety[k]}) }
            for(let k in out.courses.total){ ctot.push({name: k, value:out.courses.total[k]}) }

            for(let k in out.lessons.thirty){ l30.push({name: k, value:out.lessons.thirty[k]}) }
            for(let k in out.lessons.ninety){ l90.push({name: k, value:out.lessons.ninety[k]}) }
            for(let k in out.lessons.total){ ltot.push({name: k, value:out.lessons.total[k]}) }

            for(let k in out.pages.thirty){ p30.push({name: k, value:out.pages.thirty[k]}) }
            for(let k in out.pages.ninety){ p90.push({name: k, value:out.pages.ninety[k]}) }
            for(let k in out.pages.total){ ptot.push({name: k, value:out.pages.total[k]}) }

            for(let k in out.referer.thirty){ r30.push({name: k, value:out.referer.thirty[k]}) }
            for(let k in out.referer.ninety){ r90.push({name: k, value:out.referer.ninety[k]}) }
            for(let k in out.referer.total){ rtot.push({name: k, value:out.referer.total[k]}) }

            for(let k in out.materials.thirty){ m30.push({name: k, value:out.materials.thirty[k]}) }
            for(let k in out.materials.ninety){ m90.push({name: k, value:out.materials.ninety[k]}) }
            for(let k in out.materials.total){ mtot.push({name: k, value:out.materials.total[k]}) }

            out.courses.thirty = _reverse(_sortBy(c30, ['value']))
            out.courses.ninety = _reverse(_sortBy(c90, ['value']))
            out.courses.total =  _reverse(_sortBy(ctot, ['value']))

            out.pages.thirty = _reverse(_sortBy(p30, ['value']))
            out.pages.ninety = _reverse(_sortBy(p90, ['value']))
            out.pages.total =  _reverse(_sortBy(ptot, ['value']))

            out.lessons.thirty = _reverse(_sortBy(l30, ['value']))
            out.lessons.ninety = _reverse(_sortBy(l90, ['value']))
            out.lessons.total =  _reverse(_sortBy(ltot, ['value']))

            out.materials.thirty = _reverse(_sortBy(m30, ['value']))
            out.materials.ninety = _reverse(_sortBy(m90, ['value']))
            out.materials.total =  _reverse(_sortBy(mtot, ['value']))

            out.referer.thirty = _reverse(_sortBy(r30, ['value']))
            out.referer.ninety = _reverse(_sortBy(r90, ['value']))
            out.referer.total =  _reverse(_sortBy(rtot, ['value']))

            return out;
        },
        showDates: function(){
            return {thirty: thirtyDays, ninety: ninetyDays}
        },   
        courselist: function() {
            let list = [  ]
            for(let k in this.courses.list){
                list.push(this.courses.list[k].reftitle);
            }
            list.sort()
            list.unshift('All')
            return list;
        }     
    },
    methods: {
        loadStats: function(){
            this.isLoading = true;
            this.$store.dispatch("reports/getStats")
            .then((result) => {
                this.statsData = result;
                this.isLoading = false;
                
            })
            .catch((e) => {
                // if(e) console.log(e);   
            })
        },

        handleAction: function(action, params) {
            switch(action){
                case 'Counts':
                case 'Courses':
                case 'Lessons':
                case 'Materials':
                case 'Referers':
                case 'Pages':
                    this.stateView = action;
                    break;
            }

        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },         
    },
    mounted(){
        this.loadStats()
    }
}
</script>