<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>    
        <div v-else-if="isLoading || !adminstore || !adminstore.siteconfig">
            {{ loc('Loading...') }}
        </div>  
        <div v-else>
            <AdminTitleBar
                v-if="titleBarActions"
                :title="loc('Site Configuration')"
                :actions="titleBarActions"
                v-on:actionhandler="handleAction"
            />
            <div v-if="panel === 'json'">
                <pre>{{adminstore.siteconfig}}</pre>
            </div>
            <div v-else-if="panel === 'navitems'">
                <AdminConfigNav 
                    :navlist="adminstore.siteconfig.navitems" 
                    :options="{lang: path1}" 
                    v-on:actionhandler="handleAction"
                />
            </div>
            <div v-else >
                <ContentBlockSimple :record="'adminConfigIntro'"  />

                <div>
                    <v-row class="pt-6">
                        <v-col cols="2" class="label">{{ loc('Navigation') }}</v-col>
                        <v-col><router-link to="/admin/config/navitems" >{{ loc('Manage') }}</router-link></v-col>
                    </v-row>
                    <v-row ><v-col cols="12" class="tip"  style="border-bottom:1px solid #ccc" ></v-col></v-row>                    
                </div>

                <div v-for="(item, key) in schema" v-bind:key="key" >

                    <v-row v-if="(!item.hide || showhidden) && item.type === 'keypair'" class="pt-6" >
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value">
                                <v-row v-for="(v,k) in adminstore.siteconfig[key]" v-bind:key="key +'-' + k" >
                                    <v-col v-if="editkey === key" cols="3">
                                        <v-text-field name="key" :value="k" outlined  @change="updateKey(k, $event)"></v-text-field>
                                    </v-col>
                                    <v-col v-if="editkey === key">
                                        <v-text-field name="value" :value="parseValue(v)" outlined  @change="updateValue(k, $event)"></v-text-field>
                                    </v-col>
                                    <v-col v-if="editkey !== key" class="align-right"  cols="3">
                                        {{k}} :
                                    </v-col>
                                    <v-col v-if="editkey !== key">
                                        {{v}}
                                    </v-col>
                                </v-row>
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>

                    <v-row v-else-if="(!item.hide || showhidden) && item.type === 'booleanlist'" class="pt-6" >
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value">
                                <!-- <v-row v-for="(v,k) in adminstore.siteconfig[key]" v-bind:key="key +'-' + k" > -->
                                <v-row v-for="(k) in item.keys" v-bind:key="key + '-' + k">
                                    <v-col v-if="editkey === key" cols="3">
                                        {{loc(k)}} 
                                    </v-col>
                                    <v-col v-if="editkey === key">
                                        <v-checkbox name="value" v-model="editvalue[k]" ></v-checkbox>
                                        <!-- <v-text-field name="value" :value="v" outlined  @change="updateValue(k, $event)"></v-text-field> -->
                                    </v-col>
                                    <v-col v-if="editkey !== key" class="align-right"  cols="3">
                                        {{loc(k)}} :
                                    </v-col>
                                    <v-col v-if="editkey !== key">
                                        {{adminstore.siteconfig[key][k] ? 'true' : 'false'}}
                                    </v-col>
                                </v-row>
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>

                    <v-row v-else-if="(!item.hide || showhidden) && item.type === 'boolean'" class="pt-6">
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value" v-if="editkey === key">
                            <v-checkbox name="value" v-model="editvalue" ></v-checkbox>
                            <!-- <v-text-field name="key" v-model="editvalue" outlined ></v-text-field> -->
                        </v-col>
                        <v-col cols="9" class="value" v-else>
                            {{adminstore.siteconfig[key] ? 'true' : 'false'}}
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>  

                    <v-row v-else-if="(!item.hide || showhidden) && item.type === 'json'" class="pt-6">
                        <v-col cols="12" class="tip"  v-html="loc(item.tip)"></v-col>
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value" v-if="editkey === key">
                            <!-- <v-text-field name="key" v-model="editvalue" outlined ></v-text-field> -->
                            <SharedEditorCode :data="JSON.stringify(adminstore.siteconfig[key], null, '\t')" mode="json" :options="{key:key}" :handleChange="editorChange" />
                        </v-col>
                        <v-col cols="9" class="value" v-else>
                            <pre>{{adminstore.siteconfig[key]}}</pre>
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>         
                    
                    <v-row v-else-if="(!item.hide || showhidden) && item.type === 'textarea'" class="pt-6">
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value" v-if="editkey === key">
                            <v-textarea name="key" v-model="editvalue" outlined ></v-textarea>
                        </v-col>
                        <v-col cols="9" class="value" v-else>
                            {{adminstore.siteconfig[key]}}
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>                    

                    <v-row v-else-if="(!item.hide || showhidden)" class="pt-6">
                        <v-col cols="2" class="label">{{(item.label && loc(item.label)) || key}} </v-col>
                        <v-col cols="9" class="value" v-if="editkey === key">
                            <v-text-field name="key" v-model="editvalue" outlined ></v-text-field>
                        </v-col>
                        <v-col cols="9" class="value" v-else>
                            {{adminstore.siteconfig[key]}}
                        </v-col>
                        <v-col cols="1" v-if="editkey === key" style="white-space: nowrap"><v-btn small color="primary" @click="handleAction('saveValue')">{{ loc('Save') }}</v-btn> <v-btn small color="secondary" @click="handleAction('cancelEdit')" class='ml-3'>{{ loc('Cancel') }}</v-btn></v-col>
                        <v-col cols="1" v-else><v-icon @click="handleAction('edit', key)">edit</v-icon></v-col>
                    </v-row>

                    <v-row v-if="(!item.hide || showhidden)" ><v-col cols="12" class="tip"  style="border-bottom:1px solid #ccc" v-html="loc(item.tip)"></v-col></v-row>
                </div>
            </div>
            <div class="pt-4">{{panel}}</div>
            <div class="pt-4"><v-btn @click="showhidden = !showhidden">Toggle Hidden</v-btn></div>
        </div>
    </v-container>
    
</template>

<script>
import { mapState } from "vuex";
// import config_system from '../config/system';
import AdminTitleBar from "./Admin_TitleBar";
import ContentBlockSimple from './Content_Block_Simple';
import SharedEditorCode from './Shared_Editor_Code.vue';
import AdminConfigNav from './Admin_Config_Nav';
import { jsonToString, strToJson } from '../lib/filter.js';

export default {
    name: "AdminConfig",
    components: { AdminTitleBar, ContentBlockSimple, SharedEditorCode, AdminConfigNav },
    data() {
        return {
            isLoading: false,
            panel: false,
            editkey: false,
            editvalue: false,
            path1: false,
            showhidden: false,
            schema: {
                // In order to be available for the client to use, config also needs to be added to /app/lib/app_clientconfig
                sitetitle: {
                    label: "Site Title"
                },
                organizationtitle: {
                    label: "Organizational Title"
                },
                organizationurl: {
                    label: "Organizational URL",
                    type: "keypair",
                },
                afterlogin: {
                    label: "Default URL path after successful login",
                    tip: "If other rules don't apply, where should the user be sent after they login?"
                },
                languages: {
                    label: "Supported Languages",
                    type: "keypair",
                    tip: "This is the list of languages available in the Admin area.  They are not necessarily listed on the navigation."
                },
                publiclanguages: {
                    label: "Languages Available to Users",
                    type: "keypair",
                    tip: "This is the list of languages displayed in the navigation to site visitors."
                },
                addresses: {
                    label: "System Email Addresses",
                    type: "keypair"
                },
                defaults: {
                    label: "Defaults",
                    type: "keypair"
                },
                trackprogress: {
                    label: "Progress Tracking",
                    type: "booleanlist",
                    keys: ['lessonview', 'quizscores', 'examscores'],
                    tip: "Supported keys: lessonview, quizscores, examscores"
                },
                quizpasspercent: {
                    label: "Quiz Percent Correct to Pass",
                    type: "number",
                    tip: "Should be a number between 1 and 100.  Default is 80."
                },
                exampasspercent: {
                    label: "Exam Percent Correct to Pass",
                    type: "number",
                    tip: "Should be a number between 1 and 100. Default is 80."
                },                
                // routing: {},
                // donations: {},
                // items: {},
                createclasswithoutcerts: {
                    label: "Allow users to create classes without certification",
                    type: "boolean"
                },
                enrollmentmessageonclass: {
                    label: "Set Enroll Message on Class",
                    type: "boolean",
                    tip: "Sets whether to set which post-enrollment message template to send to people when they sign up for a class.  The default is to set the template on the Course."
                },
                showeachmaterial: {
                    label: "Show each lesson 'material' on its own page",
                    type: "boolean"
                },
                truncatecontent: {
                    label: "Truncate long content blocks of text",
                    type: "boolean",
                    tip: "A 'Show More' button will appear when text is truncated."
                },
                showCookieOptIn: {
                    label: "Show Cookie Opt In Banner",
                    type: "boolean",
                    
                },
                middleware: {
                    label: "Event Triggers",
                    type: "json",
                    tip: "Manage what happens when certain events happen within the application.  This is tricky, so if you need help please ask.",
                    hide: true
                },

                integration: {
                    label: "VCRM Integration Mapping",
                    type: "json",
                    hide: true
                },
                maincourses: {
                    label: "Main Course Map",
                    type: "json",
                    tip: "The Main Course is used to tie courses together, which is important in particular for self paced classes.  If someone is in Basics Spanish and changes languages to Basics Portuguese, we want to treat them like they are in that course.  This is built dynamically, and does not need to be edited here.",
                    hide: true
                },
                navitems: {
                    label: "Nav Items",
                    type: "json",
                    tip: "Use the Navigation page instead of this if possible.",
                    hide: true
                },
                countries: {
                    label: "Countries",
                    type: "keypair",
                    hide: true
                },
                // cookiethirdpartyscript: {
                //     label: "Third Party Tracking",
                //     type: 'textarea',
                //     hide: true,
                //     tip: "This will be injected into a script tage in the page HEAD if the visitor is opted in for third party tracking cookies.  Be careful."
                // }
                // classterms: {},
                // coursecategories: {},
                // captcha: {},
                // modules: {},
            },
            
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize", "adminstore"]),
        canEdit: function(){
            return this.users.currentUser.roles && this.users.currentUser.roles.admin;
        },
        titleBarActions: function(){
            return [
                { text: "Refresh", key: "refresh" },
                { text: (this.panel && this.panel !== 'list') ? "Close" : "JSON", key: "togglePanel"}
            ]
        }

    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleAction: function(action, params, opts) {
            switch(action){
                case 'refresh':
                    this.isLoading = true;
                    this.$store.dispatch('adminstore/getSiteConfig')
                        .then(() => {
                            this.isLoading = false;
                        })
                    break;
                case 'togglePanel':
                    {
                        this.panel = (this.panel && this.panel !== 'list') ? 'list' : 'json';
                        let path = (this.panel && this.panel !== 'list') ? '/admin/config/' + this.panel : 'admin/config';
                        this.$router.push(path);
                        break;
                    }
                case 'toggleLoading':
                    this.isLoading = params;
                    break;
                case 'edit':
                    this.editkey = params;
                    this.editvalue = (typeof this.adminstore.siteconfig[params] === 'object') ? {...this.adminstore.siteconfig[params]} : this.adminstore.siteconfig[params];
                    console.log(action, params, opts);
                    break;
                case 'cancelEdit':
                    this.editkey = false;
                    this.editvalue = false;
                    break;
                case 'saveValue':
                    {
                        this.isLoading = true;
                        console.log("Save", params, this.editkey, this.editvalue);
                        let change = {};
                        change[this.editkey] = this.editvalue;
                        this.$store.dispatch('adminstore/setSiteConfig', change)
                            .then((result) => {
                                this.isLoading = false;
                                this.editkey = false;
                                this.editvalue = false;
                            })
                    }
                    break;
            }

        },
        updateKey: function(k, v){
            console.log('updateKey', k, v);
            this.editvalue[v] = this.editvalue[k];
            delete this.editvalue[k];
        },
        updateValue: function(k, v){
            console.log('updateValue', k, strToJson(v))
            this.editvalue[k] = strToJson(v);
        },
        parseValue: function(value){
            
            return (typeof value === 'object') ? jsonToString(value) : value; // we'll stringify here if its json.
        },
        editorChange: function(value, key){
            // console.log('editorChange', value, key)
            // let value;
            try{
                value = JSON.parse(value);
            } catch(e){
                console.log("Error parsing json value", value);
                return;
            }
            console.log("Valid JSON in", key, this.editkey, this.editvalue, value);
            this.editvalue = value;
        },
        init: function() {
            if(this.$route.params.pathMatch && this.$route.params.pathMatch.length){
                let p = this.$route.params.pathMatch.split('/');
                if(this.panel !== p[0] && p[0] !== 'admin') this.panel = p[0];
                this.path1 = p[1] || false;
            }
        }
    },
    created() {
        // this.$store.dispatch('adminstore/getSiteConfig');
        this.init();
    },
    watch: {
        '$route.params': {
            handler: function(s){
                console.log('admin.config.watch triggered', s);
                this.init();
            },
            deep: true,
            immediate: false
        }
    }
}
</script>